import React from "react";
import { navigate } from "gatsby";

const NotFoundPage = () => {
  React.useEffect(() => {
    navigate("/integrate");
  }, []);

  return null;
};

export default NotFoundPage;
